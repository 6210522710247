import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { routesMain } from "routes.js";
import { isAuth } from "utils/auth";
import Login from "components/Login";

const Routers = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route exact path={prop.path} component={prop.component} key={key}>
          {isAuth() ? (
            <prop.component></prop.component>
          ) : (
            <Redirect to="/login"></Redirect>
          )}
        </Route>
      );
    });
  };

  return (
    <>
      <Switch>
        {getRoutes(routesMain("/admin"))}
        <Route exact path="/login" component={Login}>
          {isAuth() ? (
            <Redirect to="/list_register_voice"></Redirect>
          ) : (
            <Login></Login>
          )}
        </Route>
        <Redirect from="*" to="/list_register_voice" />
      </Switch>
    </>
  );
};

export default Routers;
