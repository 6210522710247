import RegisterVoice from "components/RegisterVoice";
import VerifyVoice from "components/VerifyVoice";
import ListRegisterVoice from "components/ListRegisterVoice";
import Login from "components/Login";
import DetailVoiceprint from "components/ListRegisterVoice/DetailVoiceprint.js";

var routes = [
  {
    key: 1,
    path: "/list_register_voice",
    name: "Danh sách đăng ký giọng nói",
    icon: "far fa-list-alt text-info",
    component: ListRegisterVoice,
    layout: "/admin",
  },
  {
    key: 2,
    path: "/list_register_voice/voiceprint/:id",
    name: "Chi tiết voiceprint",
    icon: "far fa-list-alt text-info",
    component: DetailVoiceprint,
    layout: "/admin",
  },
  {
    key: 3,
    path: "/register_voice",
    name: "Đăng ký giọng nói",
    icon: "fas fa-microphone-alt text-primary",
    component: RegisterVoice,
    layout: "/admin",
  },
  {
    key: 4,
    path: "/verify_voice",
    name: "Xác thực giọng nói",
    icon: "fas fa-user-check text-success",
    component: VerifyVoice,
    layout: "/admin",
  },
  {
    key: 5,
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
];

const keyNotUse = [2, 5];
const navLink = routes.filter((item) => !keyNotUse.includes(item.key));
const routesMain = (type) => routes.filter((item) => item.layout === type);

export { navLink, routesMain };

export default routes;
