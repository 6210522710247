import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Progress,
} from "reactstrap";
// core components
import Admin from "layouts/Admin";
import Header from "components/Headers";
import Audio from "components/Audio";
import Swal from "sweetalert2";
import 'assets/css/chat.css';

import Select, { components } from "react-select";
import axiosApi from "api/axiosClient";
import { getUserEnroll } from "api/apiCall";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { resetToken } from "utils/auth";
import { useHistory } from "react-router-dom";

const showProcessColor = (value) => {
  if (value >= 65) {
    return "success";
  }

  if (value < 65 && value >= 45) {
    return "warning";
  } else {
    return "danger";
  }
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Không có dữ liệu</span>
    </components.NoOptionsMessage>
  );
};

const VerifyVoice = (props) => {
  const history = useHistory();
  const [fileAudio, setFileAudio] = useState();
  const [optionsEnroll, setOptionsEnroll] = useState([]);
  const [optionsEnrollClient, setOptionsEnrollClient] = useState([]);
  const [threshold, setThreshold] = useState("70");
  const [resutlVerify, setResultVerify] = useState();
  const [resultAsr, setResultAsr] = useState();
  const [actionLoading, setActionLoading] = useState({
    verify: false,
  });
  const [isPlayAudio, setIsPlayAudio] = useState();
  const [audioTime, setAudioTime] = useState();
  // value select
  const [selectAgency, setSelectAgency] = useState();
  const [selectClient, setSelectClient] = useState();

  useEffect(() => {
    async function getData() {
      // get enroll agency
      var data = {
        description: "1",
      };
      var res = await getUserEnroll(data);
      var status = res.status;
      if (status === 200) {
        const j_response = res.data;
        if (j_response.status === 0) {
          let options = j_response.data.map((item) => ({
            label: item.user_code + " - " + item.name,
            value: item.user_code,
          }));
          setOptionsEnroll(options);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      } else {
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      }

      // get enroll client
      data = {
        description: "0",
      };
      res = await getUserEnroll(data);
      status = res.status;
      if (status === 200) {
        const j_response = res.data;
        if (j_response.status === 0) {
          let options = j_response.data.map((item) => ({
            label: item.user_code + " - " + item.name,
            value: item.user_code,
          }));
          setOptionsEnrollClient(options);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      } else {
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      }
    }
    getData();
  }, []);

  const handleLoading = (key, value) => {
    setActionLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeAgency = async (op) => {
    let url = "/api/voicebio/user/check/" + op.value;
    let method = "GET";

    setSelectAgency(op);
    await axiosApi({ method: method, url: url })
      .then((res) => {
        const j_response = res.data;
        if (j_response.status === 0) {
          if (!j_response.result) {
            Swal.fire({
              title: "Yêu cầu",
              text: `Đại lý ${op.label} chưa đăng ký giọng nói.`,
              icon: "error",
              showCancelButton: false,
              reverseButtons: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Đồng ý",
            });
            setSelectAgency(null);
          }
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      });
  };

  const handleChangeClient = async (op) => {
    let url = "/api/voicebio/user/check/" + op.value;
    let method = "GET";

    setSelectClient(op);
    await axiosApi({ method: method, url: url })
      .then((res) => {
        const j_response = res.data;
        if (j_response.status === 0) {
          if (!j_response.result) {
            Swal.fire({
              title: "Yêu cầu",
              text: `Khách hàng ${op.label} chưa đăng ký giọng nói.`,
              icon: "error",
              showCancelButton: false,
              reverseButtons: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Đồng ý",
            });
            setSelectClient(null);
          }
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      });
  };

  const handleSubmit = async () => {
    if (!selectAgency) {
      Swal.fire({
        title: "Yêu cầu",
        text: "Vui lòng chọn đại lý.",
        icon: "error",
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Đồng ý",
      });
      return false;
    }

    if (!selectClient) {
      Swal.fire({
        title: "Yêu cầu",
        text: "Vui lòng chọn khách hàng.",
        icon: "error",
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Đồng ý",
      });
      return false;
    }

    if (!fileAudio) {
      Swal.fire({
        title: "Yêu cầu",
        text: "Vui lòng chọn tệp ghi âm.",
        icon: "error",
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Đồng ý",
      });
      return false;
    }

    handleLoading("verify", true);

    var url = "/api/voicebio/verify/do_drz_verify";
    var data = new FormData();
    data.append("user_code_1", selectAgency.value);
    data.append("user_code_2", selectClient.value);
    data.append("file", fileAudio);
    data.append("threshold", threshold);

    await axiosApi({
      method: "POST",
      url: url,
      data: data,
    })
      .then((res) => {
        const j_response = res.data;
        if (j_response.status === 0) {
          setResultVerify(j_response.result_voicebio);
          setResultAsr(j_response.result_asr);
        } else {
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      });

    handleLoading("verify", false);
  };

  const handleFresh = () => {
    setThreshold("70");
    setSelectAgency(null);
    setSelectClient(null);
    setFileAudio();
    setResultVerify();
  };

  return (
    <Admin {...props}>
      <>
        {/* <ToastContainer /> */}
        <Header></Header>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Xác thực</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Đại lý</label>
                            <Select
                              components={{ NoOptionsMessage }}
                              placeholder="chọn..."
                              value={selectAgency}
                              onChange={(op) => handleChangeAgency(op)}
                              options={optionsEnroll}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Khách hàng
                            </label>
                            <Select
                              components={{ NoOptionsMessage }}
                              value={selectClient}
                              onChange={(op) => handleChangeClient(op)}
                              placeholder="chọn..."
                              options={optionsEnrollClient}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-threshold"
                            >
                              Ngưỡng xác thực
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={threshold}
                              onChange={(e) => {
                                var re = /^[0-9\b]+$/;
                                var value = e.target.value;
                                if (value !== "" && re.test(value)) {
                                  setThreshold(value);
                                } else {
                                  if (value === "") {
                                    setThreshold("");
                                  }
                                }
                              }}
                              id="input-threshold"
                              placeholder="Ngưỡng..."
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Chọn tệp ghi âm
                            </label>
                            <Audio
                              file={fileAudio}
                              setFile={setFileAudio}
                              isPlay={setIsPlayAudio}
                              audioTime={setAudioTime}
                            ></Audio>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-center mt-4">
                        <Col lg="12">
                          <Button
                            color="primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={actionLoading.verify}
                          >
                            {actionLoading.verify ? (
                              <span className="text-center">
                                <ReactLoading
                                  type="bubbles"
                                  height={"100%"}
                                  width={"30px"}
                                />
                              </span>
                            ) : (
                              <>Xác thực</>
                            )}
                          </Button>
                        </Col>
                      </Row>
                      {resutlVerify ? (
                        <Row>
                          <Col xl="6">
                            <Row>
                              <Col xl="12">
                                <div className="progress-wrapper">
                                  <div className="progress-info">
                                    <div className="progress-label">
                                      <span>{selectAgency?.label}</span>
                                    </div>
                                    <div className="progress-percentage">
                                  <span>
                                    {Math.round(
                                        resutlVerify[selectAgency?.value].score
                                    )}
                                    %
                                  </span>
                                    </div>
                                  </div>
                                  <Progress
                                      max="100"
                                      value={Math.round(
                                          resutlVerify[selectAgency?.value].score
                                      )}
                                      color={showProcessColor(
                                          resutlVerify[selectAgency?.value].score
                                      )}
                                  />
                                  <div className="text-center">
                                    <audio controls>
                                      <source
                                          src={
                                            resutlVerify[selectAgency?.value]
                                                .audio_path
                                          }
                                          type="audio/mpeg"
                                      ></source>
                                    </audio>
                                  </div>
                                </div>
                              </Col>
                              <Col xl="12">
                                <div className="progress-wrapper">
                                  <div className="progress-info">
                                    <div className="progress-label">
                                      <span>{selectClient.label}</span>
                                    </div>
                                    <div className="progress-percentage">
                                  <span>
                                    {Math.round(
                                        resutlVerify[selectClient?.value].score
                                    )}
                                    %
                                  </span>
                                    </div>
                                  </div>
                                  <Progress
                                      max="100"
                                      value={Math.round(
                                          resutlVerify[selectClient?.value].score
                                      )}
                                      color={showProcessColor(
                                          resutlVerify[selectClient?.value].score
                                      )}
                                  />
                                  <div className="text-center">
                                    <audio controls>
                                      <source
                                          src={
                                            resutlVerify[selectClient?.value]
                                                .audio_path
                                          }
                                          type="audio/mpeg"
                                      ></source>
                                    </audio>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl="6">
                            <Card className="mt-2 border border-2">
                              <CardHeader className="font-weight-bold bg-light">
                                <span>Log chat</span>
                              </CardHeader>
                              <CardBody className="chat-care">
                                {(resultAsr !== undefined && resultAsr.length) ? resultAsr.map((item) =>
                                    (
                                        <ul className="chat">
                                          <li className={item.channel == "AGENT" ? "admin clearfix" : "agent clearfix"}>
                                            <span className={item.channel == "AGENT" ? "chat-img right clearfix mx-2 text-center text-sm" : "chat-img left clearfix mx-2 text-center text-sm"}>
                                                <img src={item.channel == "AGENT" ? require("../../assets/img/theme/voice.png") : require("../../assets/img/theme/voice_img.png")} height="50px" alt="Agent" className="img-circle"/>
                                                <strong className="primary-font">{item.channel}</strong>
                                            </span>
                                            <div className={item.channel == "AGENT" ? "chat-body clearfix chat-primary" : "chat-body clearfix chat-yellow"}>
                                              <p>
                                                {item.text}
                                              </p>
                                              <div className="clearfix text-time">
                                                <small className={item.channel == "AGENT" ? "left text-muted" : "right text-muted"}>
                                                  <span className="glyphicon glyphicon-time"></span>s{item.start.toFixed(2)}</small>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                    )) : (null)}
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xl="12" className="text-center mt-4">
                            <Button
                              color="default"
                              type="button"
                              onClick={handleFresh}
                            >
                              Làm mới
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </Admin>
  );
};

export default VerifyVoice;
