import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import Auth from "layouts/Auth";
import axiosApi from "api/axiosClient";

const Login = (props) => {
  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");
  const [msgError, setMsgError] = useState("");

  const handleLogin = async () => {
    let url = "/api/voicebio/auth";
    let method = "POST";
    let headers = {};

    let bodyFormData = new FormData();
    bodyFormData.append("username", userName);
    bodyFormData.append("password", pass);

    axiosApi({
      method: method,
      url: url,
      headers: headers,
      data: bodyFormData,
    })
      .then((res) => {
        let j_response = res.data;
        let status = j_response.status;
        if (status === 0) {
          localStorage.setItem("accessToken", j_response.token);
          window.location.href = "/";
        } else {
          let msg = "Tài khoản hoặc mật khẩu không chính xác.";
          setMsgError(msg);
        }
      })
      .catch((error) => {
        let status = error.response?.status;
        if (status === 401) {
          setMsgError("Có lỗi xảy ra.");
        } else if (status === 422) {
          let msg = "Vui lòng điền đầy đủ thông tin.";
          setMsgError(msg);
        } else {
          setMsgError("Có lỗi xảy ra.");
        }
      });
  };

  return (
    <>
      <Auth {...props}>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h1>Voicebio Bảo Hiểm</h1>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="username"
                      type="username"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      autoComplete="new-username"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>
                <small className="text-danger">{msgError}</small>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={handleLogin}
                  >
                    Đăng nhập
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Auth>
    </>
  );
};

export default Login;
