import React, { useEffect, useState } from "react";

import { Input, Row, Col, FormText, Button } from "reactstrap";

function MultipleAudio({ keyname, fileAudio, setFilesAudio, text = "" }) {
  const [urlAudio, setUrlAudio] = useState();
  const [fileNameAudio, setFileNameAudio] = useState("");

  useEffect(() => {
    if (!fileAudio) {
      setUrlAudio();
    }
  }, [fileAudio]);

  const handleClickButton = () => {
    document.getElementById("file_audio" + keyname).click();
  };

  const handleChangeFile = ({ target }) => {
    let _file = target?.files[0];
    if (_file?.name) {
      let url = URL.createObjectURL(_file);
      setUrlAudio(url);
      setFileNameAudio(_file?.name);
      setFilesAudio((prev) => ({
        ...prev,
        [keyname]: _file,
      }));
    } else {
      setUrlAudio();
      setFileNameAudio("");
      setFilesAudio((prev) => ({
        ...prev,
        [keyname]: null,
      }));
    }
  };

  return (
    <Col xl="10">
      <Row className="justify-content-center align-items-center">
        <Col xl="3">
          <Button
            className="mb-3"
            color="primary"
            type="button"
            size="sm"
            outline
            onClick={handleClickButton}
          >
            Chọn tệp {text}
            <Input
              hidden
              id={"file_audio" + keyname}
              name="file"
              type="file"
              accept="audio/,.mp3,.wav,.m4a"
              onChange={handleChangeFile}
            />
          </Button>
        </Col>
        <Col xl="9" className="d-flex align-items-center">
          {urlAudio ? (
            <div className="w-100">
              <audio controls className="w-100">
                <source src={urlAudio} type="audio/ogg"></source>
              </audio>
              <FormText>Tên tệp: {fileNameAudio}</FormText>
            </div>
          ) : (
            <FormText>Hãy chọn tệp ghi âm</FormText>
          )}
        </Col>
      </Row>
    </Col>
  );
}

export default MultipleAudio;
