import axios from "axios";

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

axiosApi.interceptors.request.use(
  async (config) => {
    let accessToken = await localStorage.getItem("accessToken");
    config.headers["Authorization"] = "Bearer " + accessToken;
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosApi;
