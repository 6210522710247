import React, { useEffect, useState } from "react";

import { Card, CardHeader, Container, Row, Col, CardBody } from "reactstrap";

import Admin from "layouts/Admin";
import Header from "components/Headers";
import axiosApi from "api/axiosClient";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { ConvertTime } from "utils";
import { resetToken } from "utils/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function DetailVoicePrint(props) {
  const history = useHistory();
  const { id: user_code } = useParams();
  const [voiceprint, setVoiceprint] = useState();
  const [audios, setAudios] = useState([]);

  useEffect(() => {
    async function getData() {
      var url = "/api/voicebio/voiceprint/get_voiceprint_by_usercode";
      var method = "POST";
      var voiceprints;

      var data = new FormData();
      data.append("user_code", user_code);

      await axiosApi({ method: method, url: url, data: data })
        .then((res) => {
          const j_response = res.data;
          if (j_response.status === 0) {
            if (j_response.data.length) {
              voiceprints = j_response.data[0];
              setVoiceprint(j_response.data[0]);
            }
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        });

      if (voiceprints) {
        url = `/api/voicebio/voiceprint/list_file/${voiceprints?.id}`;
        method = "GET";

        await axiosApi({ method: method, url: url })
          .then((res) => {
            const j_response = res.data;
            if (j_response.status === 0) {
              setAudios(j_response.data);
            } else {
              toast.error("Có lỗi xảy ra.", {
                autoClose: 1000,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status === 401 || status === undefined) {
              resetToken(history);
            } else {
              toast.error("Có lỗi xảy ra.", {
                autoClose: 1000,
                theme: "colored",
              });
            }
          });
      }
    }

    getData();
  }, []);

  return (
    <Admin {...props}>
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="justify-content-between">
                    <Col>
                      <h3 className="mb-0">
                        Chi tiết Voiceprint - {user_code}
                      </h3>
                      <small>
                        Ngày đăng ký: {ConvertTime(voiceprint?.enrolled_at)}
                      </small>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {audios.length ? (
                    <Row>
                      {audios.map((item, index) => (
                        <Col xl="8" key={item.id} className="mb-2">
                          <div>Ghi âm {index + 1}</div>
                          <audio controls className="w-100">
                            <source
                              src={item.audio_link}
                              type="audio/mpeg"
                            ></source>
                          </audio>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <ReactLoading
                          type="bubbles"
                          height={"10%"}
                          width={"10%"}
                          color="#3085d6"
                        />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    </Admin>
  );
}

export default DetailVoicePrint;
