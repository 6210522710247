import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Admin from "layouts/Admin";
import Header from "components/Headers";
import MultipleAudio from "components/Audio/MultipleAudio";
import Select, { components } from "react-select";
import axiosApi from "api/axiosClient";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import queryString from "query-string";
import { toast } from "react-toastify";
import { resetToken } from "utils/auth";
import { useHistory } from "react-router-dom";

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Không có dữ liệu</span>
    </components.NoOptionsMessage>
  );
};

const RegisterVoice = (props) => {
  const history = useHistory();
  const { user_code: userCode } = queryString.parse(window.location.search);
  const [optionsEnroll, setOptionsEnroll] = useState([]);
  const [selectEnroll, setSelectEnroll] = useState();
  const [totalFile, setTotalFile] = useState(1);
  const [filesAudio, setFilesAudio] = useState({
    file1: null,
  });
  const [actionLoading, setActionLoading] = useState({
    register: false,
  });

  const handleFresh = () => {
    setTotalFile(1);
    setFilesAudio({ file1: null });
    setSelectEnroll(null);
  };

  const handleAddFile = () => {
    var total = totalFile + 1;
    setFilesAudio((prev) => ({
      ...prev,
      ["file" + total]: null,
    }));
    setTotalFile((prev) => prev + 1);
  };

  const handleDeleteFile = (key) => {
    var newValue = { ...filesAudio };
    delete newValue[key];
    setFilesAudio(newValue);
  };

  useEffect(() => {
    const getUserEnroll = async () => {
      let url = "/api/voicebio/user/list_user";
      let method = "POST";
      let data = new FormData();
      data.append("x", 1);

      await axiosApi({
        method: method,
        url: url,
        data: data,
      })
        .then((res) => {
          const j_response = res.data;
          if (j_response.status === 0) {
            let options = j_response.data.map((item) => ({
              label: item.user_code + " - " + item.name,
              value: item.user_code,
            }));
            setOptionsEnroll(options);
            let find_user = options.find((item) => item.value === userCode);
            if (find_user) {
              setSelectEnroll(find_user);
            }
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        });
    };

    getUserEnroll();
  }, []);

  const handleChangeEnroll = async (op) => {
    let url = "/api/voicebio/user/check/" + op.value;
    let method = "GET";

    setSelectEnroll(op);
    await axiosApi({ method: method, url: url })
      .then((res) => {
        const j_response = res.data;
        if (j_response.status === 0) {
          if (j_response.result) {
            Swal.fire({
              title: `<h1>Người dùng này đã đăng ký từ trước.</h1>`,
              text: "Bạn có chắc chắn muốn đăng ký lại?",
              icon: "warning",
              cancelButtonColor: "#ccc",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Có",
              cancelButtonText: "Không",
              focusCancel: true,
              showCancelButton: true,
              reverseButtons: true,
            }).then((result) => {
              if (result.isDismissed) {
                setSelectEnroll(null);
              }
            });
          }
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      });
  };

  const handleSubmit = async () => {
    if (!selectEnroll) {
      Swal.fire({
        title: "Yêu cầu",
        text: "Vui lòng chọn người đăng ký.",
        icon: "error",
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Đồng ý",
      });
      return false;
    }

    var listKey = Object.keys(filesAudio);
    for (let i = 0; i < listKey.length; i++) {
      if (!filesAudio[listKey[i]]) {
        Swal.fire({
          title: "Yêu cầu",
          text: `Vui lòng chọn tệp ghi âm thứ ${i + 1}.`,
          icon: "error",
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Đồng ý",
        });
        return false;
      }
    }

    setActionLoading((prev) => ({
      ...prev,
      register: true,
    }));
    var check_file = false;

    // handle reset file audio registered of enroll
    var url = "/api/voicebio/enroll/reset";
    var method = "POST";

    let data = new FormData();
    data.append("user_code", selectEnroll.value);

    await axiosApi({ method: method, url: url, data: data })
      .then((res) => {
        var j_response = res.data;
        if (j_response.status !== 0) {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
          check_file = true;
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
        check_file = true;
      });

    if (check_file) {
      setActionLoading((prev) => ({
        ...prev,
        register: false,
      }));
      return false;
    }

    // handle add audio of enroll
    url = "/api/voicebio/enroll/add_file";
    for (let i = 0; i < listKey.length; i++) {
      let check = false;
      data = new FormData();
      data.append("user_code", selectEnroll.value);
      data.append("file", filesAudio[listKey[i]]);

      await axiosApi({ method: method, url: url, data: data })
        .then((res) => {
          var j_response = res.data;
          if (j_response.status !== 0) {
            check = true;
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
          check = true;
        });

      if (check) {
        Swal.fire({
          title: "Có lỗi xảy ra",
          text: `Tệp ghi âm thứ ${i + 1} thêm bị lỗi.`,
          icon: "error",
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Đồng ý",
        });

        setActionLoading((prev) => ({
          ...prev,
          register: false,
        }));
        return false;
      }
    }

    // handle enroll
    url = "/api/voicebio/enroll/do_enroll";
    data = new FormData();
    data.append("user_code", selectEnroll.value);
    await axiosApi({
      method: method,
      url: url,
      data: data,
    })
      .then((res) => {
        const j_response = res.data;
        if (j_response.status === 0) {
          handleFresh();
          toast.success("Đăng ký thành công.", {
            autoClose: 1000,
            theme: "colored",
          });
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status === 401 || status === undefined) {
          resetToken(history);
        } else {
          toast.error("Có lỗi xảy ra.", {
            autoClose: 1000,
            theme: "colored",
          });
        }
      });

    setActionLoading((prev) => ({
      ...prev,
      register: false,
    }));
  };

  return (
    <Admin {...props}>
      <>
        <Header></Header>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Đăng ký</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Người đăng ký
                            </label>
                            <Select
                              components={{ NoOptionsMessage }}
                              placeholder="chọn..."
                              value={selectEnroll}
                              onChange={(op) => handleChangeEnroll(op)}
                              options={optionsEnroll}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label mb-4">
                              Chọn tệp ghi âm
                            </label>
                            {Object.keys(filesAudio).map((item, index) => (
                              <Row
                                key={"Item" + item}
                                className="justify-content-center align-items-center mb-3 mx-2"
                              >
                                <MultipleAudio
                                  keyname={item}
                                  fileAudio={filesAudio[item]}
                                  setFilesAudio={setFilesAudio}
                                  text={index + 1}
                                ></MultipleAudio>
                                <Col xl="2">
                                  {item === "file1" ? (
                                    <></>
                                  ) : (
                                    <Button
                                      className="mt-2"
                                      size="sm"
                                      color="danger"
                                      type="button"
                                      onClick={() => handleDeleteFile(item)}
                                    >
                                      Xóa
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                            <div className="text-center mt-3">
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                type="button"
                                onClick={handleAddFile}
                              >
                                + Thêm tệp
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="text-center mt-4">
                        <Col lg="12">
                          <Button
                            color="primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={actionLoading.register}
                          >
                            {actionLoading.register ? (
                              <span className="text-center">
                                <ReactLoading
                                  type="bubbles"
                                  height={"100%"}
                                  width={"30px"}
                                />
                              </span>
                            ) : (
                              <>Đăng ký</>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </Admin>
  );
};

export default RegisterVoice;
