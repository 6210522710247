function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const ConvertTime = (timestamp) => {
  if (timestamp) {
    const dt = new Date(timestamp);
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    const dformat = `${padL(dt.getDate())}/${padL(
      dt.getMonth() + 1
    )}/${dt.getFullYear()} ${padL(dt.getHours())}:${padL(
      dt.getMinutes()
    )}:${padL(dt.getSeconds())}`;
    return dformat;
  }
  return "";
};

export { isNumeric, ConvertTime };
