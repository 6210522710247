import React, { useEffect, useState } from "react";
import { Button, Input, FormGroup, Form } from "reactstrap";

import Select, { components } from "react-select";
import axiosApi from "api/axiosClient";
import Swal from "sweetalert2";
import { resetToken } from "utils/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const options = [
  { label: "Đại lý", value: "1" },
  { label: "Khách hàng", value: "0" },
];

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Không có dữ liệu</span>
    </components.NoOptionsMessage>
  );
};

function FormVoice({
  dataUpdate,
  setFormModal,
  setReLoad,
  typeForm = "register",
}) {
  const history = useHistory();
  const [userCode, setUserCode] = useState("");
  const [userName, setUserName] = useState("");
  const [selectType, setSelectType] = useState(options[0]);

  useEffect(() => {
    if (typeForm === "update") {
      var type = options.find((item) => item.value === dataUpdate.description);
      setUserCode(dataUpdate.user_code);
      setUserName(dataUpdate.name);
      setSelectType(type);
    }
  }, []);

  const reFreshForm = () => {
    setReLoad((prev) => !prev);
    setFormModal(false);
    setUserCode("");
    setUserName("");
  };

  const handleSubmit = async () => {
    if (typeForm === "register") {
      if (!userCode) {
        Swal.fire({
          title: "Yêu cầu",
          text: "Vui lòng nhập user code.",
          icon: "error",
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Đồng ý",
        });
        return false;
      }

      if (!userName) {
        Swal.fire({
          title: "Yêu cầu",
          text: "Vui lòng nhập tên người dùng.",
          icon: "error",
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Đồng ý",
        });
        return false;
      }

      var url = "/api/voicebio/user/create";
      var method = "POST";

      var data = new FormData();
      data.append("user_code", userCode);
      data.append("name", userName);
      data.append("description", selectType.value);

      await axiosApi({ method: method, url: url, data: data })
        .then((res) => {
          const j_response = res.data;
          if (j_response.status === 0) {
            toast.success("Thêm mới thành công.", {
              autoClose: 1000,
              theme: "colored",
            });
            reFreshForm();
          } else {
            toast.error("User code đã tồn tại.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        });
    } else {
      if (!userName) {
        Swal.fire({
          title: "Yêu cầu",
          text: "Vui lòng nhập tên người dùng.",
          icon: "error",
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Đồng ý",
        });
        return false;
      }

      url = "/api/voicebio/user/update";
      method = "POST";

      data = new FormData();
      data.append("user_code", userCode);
      data.append("name", userName);
      data.append("description", selectType.value);

      await axiosApi({ method: method, url: url, data: data })
        .then((res) => {
          const j_response = res.data;
          if (j_response.status === 0) {
            toast.success("Cập nhật thành công.", {
              autoClose: 1000,
              theme: "colored",
            });
            reFreshForm();
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        });
    }
  };

  return (
    <div>
      <Form role="form">
        <FormGroup className="mb-3">
          <label className="form-control-label" htmlFor="input-usercode">
            User code
          </label>
          {typeForm === "register" ? (
            <Input
              id="input-usercode"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
              placeholder="Nhập user code"
              type="user_code"
            />
          ) : (
            <Input
              disabled
              id="input-usercode"
              defaultValue={userCode}
              placeholder="Nhập user code"
              type="user_code"
            />
          )}
        </FormGroup>
        <FormGroup className="mb-3">
          <label className="form-control-label">Loại đăng ký</label>
          <Select
            components={{ NoOptionsMessage }}
            placeholder="chọn..."
            options={options}
            value={selectType}
            onChange={(op) => setSelectType(op)}
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="input-username">
            Tên
          </label>
          <Input
            id="input-username"
            placeholder="Nhập tên ..."
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </FormGroup>

        <div className="text-center">
          <Button color="primary" type="button" onClick={handleSubmit}>
            {typeForm === "register" ? "Đăng ký" : "Cập nhật"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default FormVoice;
