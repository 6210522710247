import { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Col,
  Modal,
  CardBody,
} from "reactstrap";
// core components
import Header from "components/Headers";
import Admin from "layouts/Admin";
import PagingCustom from "utils/PagingCustom";
import FormVoice from "components/Form/FormVoice";
import axiosApi from "api/axiosClient";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { ConvertTime } from "utils";
import { resetToken } from "utils/auth";
import { toast } from "react-toastify";

const ListRegisterVoice = (props) => {
  const history = useHistory();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [formModal, setFormModal] = useState(false);
  const [listEnroll, setListEnroll] = useState();
  const [reLoad, setReLoad] = useState(false);
  const [typeForm, setTypeForm] = useState("register");
  const [dataUpdate, setDataUpdate] = useState({});

  useEffect(() => {
    const getUserEnroll = async () => {
      let url = "/api/voicebio/user/list_user";
      let method = "POST";
      let data = new FormData();
      data.append("page", pageCurrent);
      data.append("page_size", pageSize);

      await axiosApi({
        method: method,
        url: url,
        data: data,
      })
        .then((res) => {
          const j_response = res.data;
          if (j_response.status === 0) {
            let data = j_response.data;
            setTotalItem(j_response.total);
            setListEnroll(data);
            if (data.length === 0 && pageCurrent > 1) {
              setPageCurrent((prev) => prev - 1);
            }
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401 || status === undefined) {
            resetToken(history);
          } else {
            toast.error("Có lỗi xảy ra.", {
              autoClose: 1000,
              theme: "colored",
            });
          }
        });
    };

    getUserEnroll();
  }, [reLoad, pageCurrent]);

  const handleDelete = (user_code) => {
    Swal.fire({
      title: `<h1>Bạn có chắc chắn muốn xóa?</h1>`,
      text: "Bạn sẽ không thể hoàn tác.",
      icon: "warning",
      cancelButtonColor: "#ccc",
      confirmButtonColor: "#d33",
      confirmButtonText: "Có",
      cancelButtonText: "Không",
      focusCancel: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let url = "/api/voicebio/user/delete";
        let method = "POST";

        let bodyFormData = new FormData();
        bodyFormData.append("user_code", user_code);

        axiosApi({
          method: method,
          url: url,
          data: bodyFormData,
        })
          .then((res) => {
            const j_response = res.data;
            if (j_response.status === 0) {
              toast.success("Xóa thành công.", {
                autoClose: 1000,
                theme: "colored",
              });
              setReLoad((prev) => !prev);
            } else {
              toast.error("Có lỗi xảy ra.", {
                autoClose: 1000,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status === 401 || status === undefined) {
              resetToken(history);
            } else {
              toast.error("Có lỗi xảy ra.", {
                autoClose: 1000,
                theme: "colored",
              });
            }
          });
      }
    });
  };

  return (
    <Admin {...props}>
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="justify-content-between">
                    <Col>
                      <h3 className="mb-0">Danh sách đăng ký</h3>
                    </Col>
                    <Col className="col-auto">
                      <Button
                        size="sm"
                        color="info"
                        type="button"
                        onClick={() => {
                          setFormModal(true);
                          setTypeForm("register");
                        }}
                      >
                        Thêm mới
                      </Button>
                      <Modal
                        className="modal-dialog-centered modal-lg"
                        size="sm"
                        isOpen={formModal}
                        toggle={() => setFormModal((prev) => !prev)}
                      >
                        <div className="modal-header">
                          <h3 className="modal-title" id="modal-title-default">
                            {typeForm === "register" ? "Đăng ký" : "Cập nhật"}{" "}
                            người dùng
                          </h3>
                          <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFormModal((prev) => !prev)}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className="modal-body p-0">
                          <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 ">
                              <FormVoice
                                dataUpdate={dataUpdate}
                                typeForm={typeForm}
                                setReLoad={setReLoad}
                                setFormModal={setFormModal}
                              ></FormVoice>
                            </CardBody>
                          </Card>
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="overflow-auto">
                  <Table className="align-items-center">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">User code</th>
                        <th scope="col">Loại</th>
                        <th scope="col">Tên</th>
                        <th scope="col">Ngày đăng ký</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {listEnroll !== undefined ? (
                        listEnroll.length ? (
                          listEnroll.map((item) => (
                            <tr key={item.user_code}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {item.user_code}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              {item?.description === "1" ? (
                                <td>Đại lý</td>
                              ) : (
                                <td>Khách hàng</td>
                              )}
                              <td>{item.name}</td>
                              <td>{ConvertTime(item?.enrolled_at)}</td>
                              <td className="text-right">
                                {item.enrolled_at ? (
                                  <Button
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={() =>
                                      history.push(
                                        `/list_register_voice/voiceprint/${item.user_code}`
                                      )
                                    }
                                  >
                                    Chi tiết
                                  </Button>
                                ) : (
                                  <Button
                                    outline
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={() =>
                                      history.push(
                                        `/register_voice?user_code=${item.user_code}`
                                      )
                                    }
                                  >
                                    Đăng ký giọng
                                  </Button>
                                )}
                                <Button
                                  size="sm"
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setFormModal(true);
                                    setTypeForm("update");
                                    setDataUpdate(item);
                                  }}
                                >
                                  Sửa
                                </Button>
                                <Button
                                  size="sm"
                                  color="danger"
                                  type="button"
                                  onClick={() => handleDelete(item.user_code)}
                                >
                                  Xóa
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={10}>Không có dữ liệu nào</td>
                          </tr>
                        )
                      ) : (
                        <tr className="text-center">
                          <td colSpan={10}>Loading</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <PagingCustom
                      pageCurrent={pageCurrent}
                      totalItem={totalItem}
                      pageSize={pageSize}
                      setPageCurrent={setPageCurrent}
                    ></PagingCustom>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    </Admin>
  );
};

export default ListRegisterVoice;
