import axiosApi from "./axiosClient";

export const getUserEnroll = async (query = { test: "" }) => {
  try {
    let url = "/api/voicebio/user/list_user";
    let method = "POST";
    let data = new FormData();
    Object.keys(query).map((item) => {
      data.append(item, query[item]);
    });

    const res = await axiosApi({
      method: method,
      url: url,
      data: data,
    });

    return res;
  } catch (err) {
    return err.response;
  }
};
