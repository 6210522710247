export const isAuth = () => {
  const token = localStorage.getItem("accessToken");
  return token ? true : false;
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const resetToken = (history) => {
  localStorage.clear();
  history.push("/login");
};
